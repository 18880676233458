import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import PostLink from '../post-link';
import PostHeader from '../../../app/components/post-header';
import PostTitle from '../../../app/components/post-title';
import PostItemFooter from '../post-item-footer';
import PostItemCover from '../post-item-cover';
import styles from './recent-posts-item.scss';
import { flowRight } from 'lodash';
import withExperiment from '../../../app/hoc/with-experiment';
import { connect } from '../../../common/components/runtime-context';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT } from '@wix/communities-forum-client-commons';
import { HorizontalSeparator } from '../../../app/components/separator';
import { PostSingleRowFooter } from '../../../app/components/post-footer/post-single-row-footer';
import withCardBorderColor from '../../hoc/with-card-border-color';
import { REACT_TO_POST } from '../../../app/constants/interactions';
import { getReactionsDisplayState } from '../../../app/containers/post-reactions/post-reactions-selectors';
import withPermissions from '../../../app/hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';

const Separator = withCardBorderColor(HorizontalSeparator);

const RecentPostsItem = ({
  post,
  titleFontClassName,
  titleFontColorClassName,
  withCover,
  isPostNumbersEnabled,
  handleLikeClick,
  forPublicUser,
  isViewsCountEnabled,
  isMobile,
  isPostReactionsEnabled,
  category,
  onReactionClick,
  reactions,
  t,
  can,
}) => {
  const renderContent = (
    <div className={styles.contentContainer}>
      <div
        className={classNames(titleFontClassName, titleFontColorClassName)}
        data-hook="recent-post__title"
      >
        <PostLink post={post} className={classNames(styles.link, 'forum-link-hover-color')}>
          <PostTitle
            className={classNames(styles.title)}
            title={post.title}
            post={post}
            type={PostTitle.RECENT_POSTS_WIDGET}
            clamp={isMobile ? 1 : 2}
          />
        </PostLink>
      </div>
      {withCover && (
        <div className={styles.cover}>
          <PostItemCover post={post} height={48} width={48} />
        </div>
      )}
    </div>
  );

  const renderHeader = (
    <div
      className={classNames(
        styles.header,
        titleFontClassName,
        titleFontColorClassName,
        'icon-fill',
      )}
    >
      <PostHeader
        type={PostHeader.RECENT_POSTS_WIDGET}
        post={post}
        showPinIcon={false}
        showLockIcon={false}
        showDateBelowAvatar={true}
        showMoreButton={false}
        showMemberCard={false}
        showUserInfoInline={false}
        truncateBadgeTitle={true}
      />
    </div>
  );

  const renderFooter = (
    <PostItemFooter
      {...{
        post,
        handleLikeClick,
        titleFontClassName,
        titleFontColorClassName,
        forPublicUser,
        isViewsCountEnabled,
      }}
    />
  );

  return (
    <div className={classNames(styles.main)}>
      <div className={classNames(styles.content)}>
        {renderHeader}
        {renderContent}
      </div>
      {isPostReactionsEnabled && isPostNumbersEnabled ? (
        <div className={styles.footerWrapper}>
          <Separator className={styles.separator} />
          <PostSingleRowFooter
            category={category}
            post={post}
            origin={isMobile ? 'recentPostsWidgetMobile' : 'recentPostsWidgetDesktop'}
            onReactionClick={onReactionClick}
            reactions={reactions}
            forPublicUser={forPublicUser}
            t={t}
            can={can}
            isMobile={isMobile}
          />
        </div>
      ) : (
        <div>{isPostNumbersEnabled && renderFooter}</div>
      )}
    </div>
  );
};

RecentPostsItem.propTypes = {
  post: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  titleFontColorClassName: PropTypes.string.isRequired,
  navigation: PropTypes.object,
  withCover: PropTypes.bool.isRequired,
  isPostNumbersEnabled: PropTypes.bool.isRequired,
  handleLikeClick: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  isPostReactionsEnabled: PropTypes.bool.isRequired,
  category: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  category: getCategory(state, ownProps.post.categoryId),
  reactions: getReactionsDisplayState(state, ownProps.post._id),
  onReactionClick: (reactionCode) => {
    return actions.reactToPost({
      postId: ownProps.post._id,
      reactionCode,
    });
  },
});

export default flowRight(
  withExperiment({ isPostReactionsEnabled: EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT }),
  withPermissions,
  withTranslate,
  connect(mapRuntimeToProps),
)(RecentPostsItem);
