import { isDefined } from '../../../utils/ts-utils';
import {
  ReactedIdentityInternal,
  ReactionsDisplayState,
  ReactionsInternalState,
} from './post-reaction-types';
import { RootState } from '../../types/store-types';
import { getPost } from '../../selectors/post-selectors';
import { Member as MemberBase } from '../../../api/members/member.type';
import { Reaction, ReactionCode, ReactionCodeValue } from '@wix/comments-ooi-client/reaction-types';
import { PostState } from './post-reactions-reducer';
import { getMembersState } from '../../../common/store/members/members-selectors';
import { getBadgeByIds } from '../../selectors/badge-selectors';
import { MembersMap } from '../../../api/members/get-members-list.api';
import { FollowedMember } from '../../../common/store/members/members-types';
import { getBlankMember } from '../../../api/members/members.utils';

export const getReactionsState = (state: RootState, postId: string): ReactionsInternalState =>
  getPost(state, postId).reactions;

export const getReactionsDisplayState = (
  state: RootState,
  postId: string,
): ReactionsDisplayState => {
  const post: PostState = getPost(state, postId);
  const normalizedReactionsList = normalizeReactions(post?.reactions?.reactedIdentities);
  const total = post?.reactions?.reactedIdentities.reduce((acc, curr) => acc + curr.total, 0);

  if (post?.reactions?.type === 'IDLE') {
    return {
      pending: false,
      total,
      reactions: normalizedReactionsList,
      activeReaction: normalizedReactionsList.find((r) => r.hasReacted)?.reactionCode,
    };
  }

  return {
    pending: true,
    total,
    reactions: normalizedReactionsList,
    activeReaction: post?.reactions?.reactionCode,
  };
};

export const getReactionsIdentities = (state: RootState, postId: string): string[] => {
  const reactionsState = getReactionsState(state, postId);

  const identities = reactionsState.reactedIdentities
    .map((r) => r.identities.map((i) => i.identityId))
    .filter(isDefined)
    .flat();

  return [...new Set(identities)];
};

const normalizeReactions = (reactions: ReactedIdentityInternal[]): Reaction[] => {
  return Object.values(ReactionCodeValue).map((code) => {
    const reaction = reactions?.find((r) => r.reactionCode === code);

    return {
      reactionCode: code,
      hasReacted: reaction?.hasReacted || false,
      total: reaction?.total ?? reaction?.identities?.length ?? 0,
      identities: reaction?.identities || [],
    };
  });
};

export const getMissingMembersList = (state: RootState, postId: string): string[] => {
  const identities = getReactionsIdentities(state, postId);
  const membersState = getMembersState(state);

  return identities.filter((i) => !membersState.membersCache[i]);
};

export type OwnerListWithReactions = OwnerWithReaction[];
export type OwnerWithReaction = ReactionOwner & { reactionCode: ReactionCode };

export type ReactionOwner = MemberOwner | UnknownOwner;

export type MemberOwner = { type: 'MEMBER'; member: Member };
export type UnknownOwner = { type: 'UNKNOWN'; member: Member };

type Member = Omit<MemberBase, 'badges'> & { badges: object[] };

export type MembersReactionsDisplayState = {
  isLoading: boolean;
  membersCache: MembersMap;
  currentMember: MemberBase | undefined;
  followedMembers: FollowedMember[];
};

export const getMembersReactionsDisplayState = (state: RootState): MembersReactionsDisplayState => {
  const membersState = getMembersState(state);
  return {
    isLoading: membersState.status === 'PENDING',
    membersCache: membersState.membersCache,
    currentMember: membersState?.currentUserId
      ? membersState.membersCache[membersState.currentUserId]
      : undefined,
    followedMembers: membersState.followedMembers || [],
  };
};

export const getReactionOwner = (
  state: RootState,
  membersCache: MembersMap,
  id: string,
): ReactionOwner => {
  const member = membersCache[id];

  if (!member) {
    return { type: 'UNKNOWN', member: { ...getBlankMember(id), badges: [] } };
  }

  const badges = getBadgeByIds(state, member?.badges) || [];
  return { type: 'MEMBER', member: { ...member, badges } };
};
