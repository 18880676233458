import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { FacebookIcon } from '../icons/facebook-icon';
import { TwitterIcon } from '../icons/twitter-icon';
import ShareIcon from '../icons/share-icon';
import styles from './post-share-button.scss';
import { withPreviewClickBlocker } from '../../../common/components/click-blocker/with-preview-click-blocker';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT } from '@wix/communities-forum-client-commons';

const ICONS = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  link: ShareIcon,
};

class PostShareButton extends Component {
  handleClick = () => {
    const { onClick, type } = this.props;
    onClick(type);
  };

  render = () => {
    const {
      type,
      title,
      contentFontClassName,
      text,
      preventClickInPreview,
      isPostReactionsEnabled,
    } = this.props;
    const iconClassName = classNames(
      styles.icon,
      'forum-text-color',
      'button-hover-color',
      contentFontClassName,
      {
        'button-hover-fill': type === 'link',
      },
    );
    const Icon = ICONS[type];

    return (
      <button
        className={classNames(styles.button, styles[type], iconClassName)}
        onClick={preventClickInPreview(this.handleClick)}
        aria-label={title}
      >
        <Icon />
        {text && (
          <span
            className={classNames(styles.share, {
              [styles.increasedFontSize]: isPostReactionsEnabled,
            })}
          >
            {text}
          </span>
        )}
      </button>
    );
  };
}

PostShareButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.func.isRequired,
  preventClickInPreview: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default flowRight(
  withPreviewClickBlocker,
  withExperiment({
    isPostReactionsEnabled: EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT,
  }),
)(PostShareButton);
